.landing-page {
  transition: transform 500ms ease 0s;

  &.active-auth {

    .auth-wrap {
      transform: initial;
    }
  }

  .shadow-block {
    display: none;
  }

  .auth-wrap {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1200;

    min-height: 100vh;
    height: 100%;
    width: 100%;

    transform: translate3d(100%, 0, 0);
    transition: all 500ms ease 0s;
  }

  .auth-container {
    position: relative;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 31px 16px;
    line-height: 1.5;

    background-image: linear-gradient(to right, #202252, $gray-44);

    .img-blob {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 15;
      height: 101px;
    }

    .heading-block {
      position: relative;
      z-index: 20;

      padding-bottom: 65px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .question-button {
        color: white;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        width: auto;
        height: auto;
      }
    }

    h1 {
      color: white;
      font-size: 30px;
      margin-bottom: 8px;

      span {
        color: $blue-34;
      }
    }

    .divider {
      display: block;
      width: 106px;
      height: 3px;
      border-radius: 25px;
      background-color: $blue-34;

      margin-bottom: 16px;
    }

    .info {
      font-size: 18px;
      font-weight: bold;
      color: $white-fe;
      letter-spacing: 0.11px;
    }

    .form-input {
      width: 100%;
      line-height: 1.5;
      padding-bottom: 16px;

      .label-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 8px;

        label {
          font-size: 16px;
          color: $green-83;
          font-weight: 600;
        }

        a {
          font-size: 11px;
          color: white;
          font-weight: 300;
          text-decoration: underline;
        }

        .link {
          font-size: 11px;
          color: white;
          font-weight: 300;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        height: 40px;

        padding-left: 14px;

        border-radius: 5px;
        border: 1px solid $gray-a0;
        background-color: $gray-66;
        color: white;
      }

      .form-error {
        color: $red-ff
      }
    }

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .arrow-img {
        position: absolute;
        z-index: 15;
        top: 27px;
        right: 10px;
        height: 12px;

        transform: scaleY(-1) rotate(-90deg);
      }
    }

    .ant-btn {
      &.log-btn {
        padding: 8px 38px;
        height: 40px;
        width: fit-content;

        border: none;
        border-top-left-radius: 0;
        background-color: $green-83;

        font-size: 15px;
        line-height: 1.5;
        color: white;
        font-weight: 900;
      }
    }

    .ant-checkbox-wrapper {
      margin-top: 16px;

      font-size: 11px;
      font-weight: 300;
      color: white;

      .ant-checkbox-inner {
        background-color: $gray-39;
        border: none;
        border-radius: 2px;

        &:after {
          border-color: $green-83;
        }
      }
    }

    .tip-block {
      font-size: 11px;
      font-weight: 300;
      color: $white-fe;

      margin-top: 16px;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .landing-page {

    &.active-auth {
      transform: translate3d(-440px, 0, 0);

      .landing-page-header,
      .landing-page-content {
        position: relative;
        z-index: 1300;
      }

      .auth-wrap {
        transform: translate3d(100%, 0, 0);
      }

      .shadow-block {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1250;

        height: 100%;
        width: 14px;
        box-shadow: 13px 0 14px 6px rgba(0, 0, 0, 0.3);
      }
    }

    .auth-wrap {
      width: 440px;
    }

    .auth-container {
      padding: 31px 56px;

      .heading-block {
        align-items: flex-start;

        .question-button {
          font-size: 24px;
          font-weight: normal;
        }

        .ant-btn {
          height: 40px;
          width: 40px;
        }

        svg {
          height: 40px;
          width: 40px;
        }
      }

      h1 {
        font-size: 36px;
        margin-bottom: 20px;
      }


      .divider {
        width: 140px;
        margin-bottom: 20px;
      }

      .info {
        font-size: 24px;
        font-weight: bold;
      }

      .form-input {

        .label-block {
          a {
            font-size: 14px;
          }

          .link {
            font-size: 14px;
          }
        }
      }

      form {
        margin-top: 0;
        padding-top: 20px;

        .arrow-img {
          top: 40px;
          right: 45px;

          transform: scaleY(-1) rotate(-45deg);
        }
      }

      .ant-btn {
        &.log-btn {
          height: 60px;
          font-size: 24px;
        }
      }

      .ant-checkbox-wrapper {
        align-items: center;
        margin-top: 16px;
        height: 24px;

        font-size: 14px;
        font-weight: normal;
        color: white;

        .ant-checkbox {
          top: 0;
        }

        .ant-checkbox-inner {
          height: 24px;
          width: 24px;

          &:after {
            left: 30%;
            width: 7.8px;
            height: 11px
          }
        }
      }

      .tip-block {
        font-size: 14px;
        font-weight: normal;

        margin-top: 34px;
      }
    }
  }
}
