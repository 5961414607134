.landing-page {
  .pricing-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-top: 40px;
    margin-bottom: 40px;

    .info-block {
      padding-right: 16px;
      padding-left: 16px;

      padding-bottom: 40px;

      h1, h2, .info {
        color: $white-fe;
        line-height: 1.5;
      }

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 18px;
      }

      .info {
        font-size: 14px;
        padding-bottom: 16px;
      }

      .currency-tooltip {
        margin-top: 14px;
        font-size: 11px;
        color: $gray-68-033;
      }
    }

    .ant-select {
      width: 160px;
      height: 40px;

      .ant-select-selector {
        height: 40px;
      }

      .ant-select-selection-item {
        line-height: 40px;
      }
    }
  }
}

.price-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .billing-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    color: $white-fe;

    height: 228px;
    width: 228px;
    padding-top: 32px;

    .text-block {
      position: relative;
      z-index: 15;
      font-weight: bold;
      text-align: center;
    }

    .price, .info, .sub-title {
      line-height: 1.5;
      font-weight: bold;
    }

    .price {
      font-size: 30px;
    }

    .info {
      font-size: 18px;
    }

    .sub-title {
      font-size: 14px;
      font-weight: initial;
      margin-bottom: 24px;
    }

    &.right {
      margin-top: 24px;

      img {
        left: initial;
        // right: 0;
      }
    }

    img {
      position: absolute;
      height: 228px;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  .discount-block {
    position: absolute;
    z-index: 20;
    left: calc(50% + 64px);
    top: -26px;

    .discount {
      position: relative;
      z-index: 25;

      height: 80px;
      width: 80px;
      padding-top: 16px;

      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;

      .percents {
        font-size: 16px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      height: 80px;
    }
  }

  .gray-blob {
    position: absolute;
    width: 320px;
    top: 0;
    left: calc(50% - 160px);
    z-index: 5;
  }
}

@media (min-width: $medium-screen) {
  .landing-page {
    .pricing-block {
      flex-direction: row;
      justify-content: center;

      margin-top: 80px;
      margin-bottom: 92px;

      .info-block {
        padding: 84px 62px 126px 0;

        h1 {
          font-size: 52px;
          color: $blue-2e;
          line-height: 1;
          margin-bottom: 32px;
        }

        h2 {
          font-size: 24px;
          color: $blue-34;
          margin-bottom: 22px;
          width: 415px;
        }

        .info {
          font-size: 14px;
          color: $gray-44;
          padding-bottom: 36px;
        }
      }
    }
  }

  .price-block {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 27px;
    width: 658px;

    .billing-info {
      color: white;

      height: 311px;
      width: 100%;
      padding-top: 70px;

      &.right {
        margin-top: 0;
      }

      .price, .info, .sub-title {
        line-height: initial;
      }

      .price {
        font-size: 52px;
        margin-bottom: 5px;
      }

      .info {
        font-size: 14px;
        margin-bottom: 15px;
      }

      .sub-title {
        font-size: 24px;
      }

      img {
        height: 311px;
      }
    }

    .discount-block {
      top: -26px;

      .discount {
        height: 122px;
        width: 126px;
        padding-top: 30px;

        font-size: 24px;
        line-height: initial;
        font-weight: bold;

        .percents {
          font-size: 36px;
        }
      }

      img {
        height: 122px;
      }
    }

    .gray-blob {
      left: 0;
      width: 100%;
    }
  }
}
