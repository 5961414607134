.landing-page {
  .get-started-block {
    position: relative;
    width: 100%;
    padding-bottom: 24px;

    .info-block {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 250px;
      padding-left: 16px;
      padding-right: 16px;

      text-align: center;
      z-index: 5;
    }

    .try-it-btn {
      font-size: 15px;
      height: 40px;
      width: 120px;
      font-weight: 600;
    }

    .try-it {
      color: $orange-ff;
    }

    h2 {
      font-size: 18px;
      color: white
    }

    .divider {
      width: 60px;
      height: 3px;
      margin-bottom: 8px;

      background-color: $blue-2e;
      border-radius: 25px;
    }

    .info {
      font-size: 14px;
      color: white;
      // margin-bottom: 29px;
      line-height: 1.5;
      text-align: left;
      padding: 0 16px;
    }

    .blob-bg {
      position: absolute;
      overflow-x: hidden;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      display: flex;
      justify-content: center;

      img {
        height: calc(100% - 24px);
      }
    }

    .arrow-img {
      position: absolute;
      z-index: 10;
      left: 90px;
      bottom: 110px;

      height: 12px;
      transform: rotate(90deg);
    }

    .icon-block {
      position: absolute;
      z-index: 10;
      height: 24px;
      opacity: 0.5;

      &.message {
        bottom: 0;
        left: 168px;
      }

      &.letter {
        bottom: 34px;
        left: 68px;
      }

      &.list2 {
        width: 24px;
        height: auto;
        top: 34px;
        left: 33px;
      }

      &.pdf {
        top: 20px;
        right: 33px;
      }

      &.phone {
        bottom: 128px;
        left: 92px;
      }

      &.list {
        width: 24px;
        height: auto;
        bottom: 47px;
        right: 33px;
      }

      &.cloud {
        width: 24px;
        height: auto;
        bottom: 150px;
        right: 52px;
      }
    }
  }

  .landing-footer {
    position: relative;
    width: 100%;
    padding-top: 53px;

    .footer-content {
      position: relative;
      z-index: 10;
    }

    .info-block {
      position: relative;

      padding-left: 16px;

      color: $gray-05;
      font-size: 12px;
      z-index: 5;

      .logo {
        height: 21px;
        margin-bottom: 8px;
      }
    }

    .info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      margin-bottom: 16px;
      height: 100%;

      line-height: 1.5;
      letter-spacing: 0.08px;
      color: $gray-af;
    }

    .cookie-icon {
      position: absolute;
      bottom: 52px;
      left: 16px;
    }

    .contact-item {
      margin-bottom: 20px;

      a {
        color: white;
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 24px;
        margin-right: 16px;
      }
    }

    .divider-wrap {
      position: relative;

      .divider {
        width: 224px;
        height: 2px;
        background-color: $blue-34;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;

          width: 16px;
          height: 2px;
          background-color: $blue-34;
        }
      }
    }

    .other-info {
      color: $gray-033;
      font-size: 11px;
      line-height: 2;
      letter-spacing: 0.07px;
      font-weight: 300;

      padding: 16px 16px 24px;
    }

    .footer-bg {
      position: absolute;
      bottom: 0;

      overflow-y: hidden;
      // height: 331px;

      img {
        width: 100vw;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .landing-page {
    .get-started-block {
      width: initial;
      padding-bottom: 0;

      .try-it-btn {
        font-size: 24px;
        height: 60px;
        width: 180px;
      }

      .info-block {
        width: 720px;
        height: 504px;
        padding-left: 0;
        padding-right: 0;
      }

      h2 {
        font-size: 36px;
      }

      .divider {
        width: 144px;
        height: 4px;
        margin-bottom: 32px;
      }

      .info {
        font-size: 24px;
        margin-bottom: 43px;
        line-height: initial;
        text-align: center;
      }

      .blob-bg {
        overflow-x: initial;

        img {
          width: 100%;
        }
      }

      .icon-block {
        height: initial;
        opacity: 1;

        &.message {
          width: 44px;
          top: 110px;
          bottom: initial;
          left: -82px;
        }

        &.letter {
          width: 60px;
          top: 210px;
          bottom: initial;
          left: -182px;
        }

        &.list2 {
          width: 42px;
          top: 152px;
          left: 49px;
        }

        &.pdf {
          width: 52px;
          top: 36px;
          right: 60px;
        }

        &.phone {
          width: 40px;
          bottom: 89px;
          left: initial;
          right: 103px;
        }

        &.list {
          width: 42px;
          top: 143px;
          bottom: initial;
          right: -101px;
        }

        &.cloud {
          width: 55px;
          bottom: 173px;
          right: -79px;
        }
      }
    }

    .landing-footer {
      padding-top: 172px;

      .info-block {
        display: grid;
        grid-column-gap: 42px;
        grid-template-columns: repeat(3, auto);

        padding-left: 180px;
        margin-bottom: 52px;
        height: 69px;
        width: fit-content;

        .logo {
          height: 19px;
          margin-bottom: 20px;
        }
      }

      .info-wrap {
        margin-bottom: 0;
      }

      .contact-item {
        margin-bottom: 0;

        img {
          width: 24px;
          margin-right: 24px;
        }
      }

      .divider-wrap {
        .divider {
          width: 960px;
          height: 2px;

          &:before {
            width: 110px;
          }
        }
      }

      .other-info {
        font-size: 9px;
        line-height: initial;
        padding: 24px 0 52px 180px;
      }

      .footer-bg {
        display: flex;
        align-items: flex-end;
        overflow-y: initial;
        // height: 806px;

        img {
          width: 100%;
        }
      }
    }
  }
}
