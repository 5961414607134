.breadcrumb-holder {
  height: 37px;
  width: 100%;
  padding: 16px;
  background-color: $gray-ea;
  align-items: center;
  justify-content: flex-start;
  flex: none;

  .button-back {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #2d3284;

    height: 100%;
  }

  .arrow-back {
    margin-right: 8px;
    height: 24px;
  }

  .breadcrumb-list {
    display: none;
  }
}

@media (min-width: $medium-screen) {
  .breadcrumb-holder {
    height: 48px;
    background-color: $white-f4;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .button-back {
      display: none;
    }

    .breadcrumb-list {
      display: flex;
      align-items: center;
      height: 100%;

      font-size: 13px;
      font-weight: bold;

      a {
        font-size: 13px;
        color: $blue-2e;
        margin-right: 5px;

        &.disabled {
          pointer-events: none;
          font-weight: normal;
          color: $gray-44;
        }
      }

      .breadcrumb-arrow {
        height: 14px;
        transform: rotate(180deg);
        margin-right: 5px;
      }
    }
  }
}
