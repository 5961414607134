body {
  line-height: normal;
  font-size: 14px;
  font-family: $base-font-family;
  overflow: scroll;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.hide {
  display: none !important;
}

.hide-mobile {
  display: none !important;
}

@media (min-width: $medium-screen) {
  .hide-mobile {
    display: inherit !important;
    flex: 1;
    justify-content: center;
  }

  .hide-desktop {
    display: none !important;
  }
}
