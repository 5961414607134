.manage-users {
  flex: auto;
  background-color: white;

  height: 100%;
  width: 100%;

  .manage-users-body {
    height: 100%;
    padding: 16px;

    h1 {
      font-size: 18px;
      color: $gray-44;
      margin-bottom: 16px;
    }

    .controls-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;

      margin-bottom: 24px;

      .filter-button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 40px;

        box-shadow: 1px 7px 20px 0 rgba(114, 114, 114, 0.15);
        border: solid 2px $gray-e1;
        background-color: $white-f4;
        cursor: pointer;

        font-size: 11px;
        font-weight: bold;
        color: $gray-44;

        &.active {
          border-color: $white-f4;
          font-weight: normal;
          box-shadow: none;
        }
      }

      .invite-button {
        height: 40px;
        border-radius: 25px;
        border: none;
        background-color: $green-83;

        font-size: 15px;
        font-weight: 700;
        color: white;
      }
    }

    .user-list {

      .user-item {
        padding: 8px 18px 8px 0;
        height: 62px;

        border-top: 1px solid $gray-e1;
        transition: height 0.2s;
        overflow: hidden;

        &.open {
          height: 130px;

          .collapsible-info {
            opacity: 1;
          }

          .arrow-collapse {
            transform: rotate(180deg);
          }
        }

        .user-badge {
          background-color:  $blue-34;
          border-radius: 6px;
          width: 35%;
          justify-self: center;
          text-align: center;
        }

        .secondary-info {
          display: flex;
          align-items: center;
        }

        .invoice-status {
          width: 66px;
          height: 25px;

          display: flex;
          justify-content: center;
          align-items: center;

          margin-right: 47px;
          border-radius: 5px;
          background-color: $blue-34;

          font-size: 11px;
          font-weight: bold;
          color: $white-fe;
        }

        .download-wrap {
          display: flex;
          justify-content: center;
          margin-top: 8px;
        }

        .user-short-info {
          display: grid;
          grid-template-columns: 1fr 97px 24px;
          align-items: center;
        }

        .truncate-block {
          flex: 1;
          min-width: 0;
        }

        .invoice-name,
        .full-name-info {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: $blue-2d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          padding-right: 10px;
        }

        .invoice-name {
          font-weight: normal;
          
        }

        .price {
          font-size: 16px;
          font-weight: 600;
          color: $gray-44;
        }

        .user-role {
          font-size: 14px;
          line-height: 1.5;
          color: $gray-44;
          // background-color: $blue-34;
        }

        .user-type-label {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 67px;
          height: 25px;

          border-radius: 5px;
          background-color: $green-83;

          font-size: 11px;
          font-weight: bold;
          color: $white-fe;

          &.inactive {
            background-color: $red-ff;
          }

          &.invited {
            background-color: $blue-34;
          }
        }

        .arrow-collapse {
          cursor: pointer;
          height: 24px;
        }

        .collapsible-info {
          opacity: 0;
          transition: opacity 0.2s;
        }

        .button-block {
          display: flex;
          justify-content: center;
          margin-top: 8px;

          .app-button {
            margin: 0 8px;
          }
        }
      }
    }

    .no-invoices {
      align-self: center;
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .billing-info-block {
    display: none;
  }
}

.delete-confirm {
  max-width: initial;
}

.delete-tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: white;

  .bold-text {
    margin-left: 5px;
    font-weight: bold;
  }

  .button-block {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .app-button {
      margin: 0 8px;
    }
  }
}

@media (min-width: $medium-screen) {

  .delete-tooltip-content {
    flex-direction: row;

    .button-block {
      margin-top: 0;
    }
  }

  .manage-users {
    border-radius: 6px;

    .manage-users-body {
      padding-left: 165px;
      padding-right: 165px;

      .invoices-container {
        display: grid;
        grid-template-columns: 5fr 2fr;
        grid-gap: 40px;

        .invoices-wrapper {
          display: flex;
          flex-direction: column;
        }

        .header-block {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ant-picker {
            margin-bottom: 16px;
          }
        }
      }

      .controls-block {
        grid-template-columns: auto auto 1fr auto;
        align-items: center;

        .filter-button {
          padding-left: 31px;
          padding-right: 31px;
          border-radius: 50px;
        }

        .invite-button {
          font-size: 12px;
          height: 28px;
        }
      }

      .hide-mobile {
        height: 100%;
      }

      .item-grid {
        display: grid;
        grid-template-columns: 2fr 2fr 3fr 1fr;
        align-items: center;
        height: 100%;
      }

      .invoice-item-grid {
        // display: grid;
        // grid-template-columns: 2fr 6fr 1fr 2fr;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .download-cell {
          justify-self: flex-end;
        }

        .price {
          justify-self: flex-end;
        }

        .name-info {
          width: 70%;
        }
      }

      .name-info {
        display: flex;
        align-items: center;
      }

      .user-list {
        .user-item {
          .invoice-status,
          .user-type-label {
            font-size: 7px;
            height: 14px;
            border-radius: 4px;
            width: auto;
            padding: 0 5px;
          }

          .user-role {
            &.email {
              font-weight: bold;
            }
          }
        }
      }
    }

    .billing-info-block {
      display: block;
      height: fit-content;

      .billing-info-head {
        display: flex;
        align-items: center;
        margin-bottom: 36px;

        h1 {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }

      .billing-info-title {
        font-size: 16px;
        font-weight: bold;
        color: $blue-2e;
      }

      .billing-info-value {
        font-size: 16px;
        color: $gray-44;
        margin-bottom: 16px;
      }
    }
  }
}
