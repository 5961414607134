.layout-footer {
  height: 76px;
  width: 100%;
  box-shadow: 0 0 15px 0 rgba(12, 12, 12, 0.25);
  background-color: $gray-44;
  padding: 16px;

  .info-wrap {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;

    line-height: 1.5;
    letter-spacing: 0.08px;
    color: $gray-af;
  }

  .cookie-icon {
    position: absolute;
    bottom: 24px;
    left: 16px;

    @media (max-width: '512px') {
      position: relative;
      bottom: 0;
    }
  }
}
