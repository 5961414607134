.form-input {
  margin-bottom: 16px;

  .label-block {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: $blue-2d;

    margin-bottom: 8px;
  }

  .ant-picker {
    border: solid 1px $gray-e1;
    padding: 12px 16px;
    border-radius: 5px;
    width: 100%;

    input {
      border: none;
      background-color: transparent;
      padding: 0;
      height: initial;
    }
  }

  .input-styles,
  input {
    width: 100%;
    height: 43px;
    border-radius: 5px;
    background-color: $white-f4;
    border: solid 1px $gray-e1;
    padding: 12px 16px;
    outline: none;
  }

  textarea {
    width: 100%;
    // height: 300px;
    border-radius: 5px;
    border: solid 1px $gray-e1;
    padding: 12px 16px;
    outline: none;
  }

  .color-input {
    display: flex;
    align-items: center;

    height: 60px;
    width: 100%;

    border-radius: 5px;
    overflow: hidden;
    border: solid 1px $gray-ea;
    background-color: $white-f1;
    cursor: pointer;

    .color-input-block {
      width: 60px;
      height: 60px;
    }

    .color-hex {
      font-size: 14px;
      text-transform: uppercase;
      color: $gray-44;

      padding-left: 16px;
      flex: 1;
    }

    .pipette-icon {
      height: 24px;
      margin-right: 18px;
    }
  }

  .overlay-cover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.form-dropdown {
  &.ant-select {
    width: 100%;
    margin-bottom: 16px;

    .ant-select-selector {
      height: 43px;
      padding: 10px 16px 9px;
      border-radius: 5px;
      border: solid 1px $gray-44;
      background-color: $white-fe;
    }

    .ant-select-arrow {
      color: $gray-44;
      width: 20px;
      height: 20px;
      top: 20px;
      right: 15px;
      font-size: 20px;
      transform: scale(1.3, 1);
    }

    .ant-select-selection-item {
      padding-right: 30px;
    }
  }
}

.form-error {
  color: $red-ff
}

.green-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 168px;
  border-radius: 25px;
  background-color: $green-83;

  border: none;

  font-size: 15px;
  font-weight: 700;
  color: white;
}

.app-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  padding: 0 31px;
  border-radius: 25px;
  border: none;

  font-size: 15px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  white-space: nowrap;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:disabled {
    background-color: $gray-e1 !important;
    color: white;
  }

  &:hover {
    opacity: 0.7;
    color: white;
  }

  &.green {
    background-color: $green-83;
  }

  &.red {
    background-color: $red-ff;
  }

  &.orange {
    background-color: $orange-ff;
  }

  &.blue {
    background-color: $blue-34;
  }
}

.form-uploader-wrap {
  .files-grid {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 16px;
    // margin-bottom: 16px;

    .file-item {
      position: relative;
      width: 100%;
      padding-top: 65%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .delete {
        position: absolute;
        top: -12px;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        cursor: pointer;

        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $red-ff;
      }
    }
  }
}

.form-uploader {

  display: flex;
  flex-direction: column;

  .ant-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    border: solid 1px $gray-e1;
    background-color: $white-f4;

    width: 100%;
    height: 241px;
    overflow: hidden;
  }

  .ant-upload-list-picture-card {
    width: 100%;
  }

  .preview-img {
    width: 100%;
    cursor: pointer;
  }

  .upload-icon {
    width: 34px;
    height: 34px;
  }

  .scraper-icon {
    height: 24px;
    margin-right: 10px;
  }

  .app-button {
    padding-left: 16px;
    padding-right: 16px;
  }

  .upload-text {
    font-size: 16px;
    font-weight: 600;
    color: $blue-2d;
    line-height: 1.5;

    margin-top: 8px;

    &.small {
      font-size: 14px;
      font-weight: normal;

      margin-bottom: 8px;
    }
  }
}

.branding-uploader:hover {
  filter: grayscale(10px);
  cursor: col-resize;
}

@media (min-width: $medium-screen) {
  .app-button {
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    padding: 0 16px;
  };
}
