.landing-page {
  .carousel-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 40px;

    width: 100%;

    .arrow-btn {
      cursor: pointer;
      height: 80px;

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .ant-carousel {
    width: 100%;

    .step {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 16px;
      padding-right: 16px;
    }

    .first-step {
      align-items: center;

      .info-block {
        width: 100%;
      }

      .image-block {
        width: 100%;
      }

      h1 {
        font-size: 30px;
        color: $blue-2e;
        line-height: 1.5;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 18px;
        color: $blue-34;
        line-height: 1.5;
        margin-bottom: 0;

        span {
          color: $blue-2e;
          margin-right: 7px;
        }
      }

      .info {
        line-height: 1.5;
        color: $blue-24;
      }
    }
  }

  .divider {
    display: none;
    justify-content: space-between;
    height: 2px;
    width: 100%;

    .left {
      width: 640px;
      background-color: $blue-2e;
    }

    .right {
      width: 120px;
      background-color: $blue-2e;
    }
  }

  .slide-numbers {
    display: flex;
    justify-content: center;
    padding: 18px 18px 40px;

    .number-btn {
      position: relative;
      height: 27px;
      width: 31px;

      margin-left: 9px;
      margin-right: 9px;

      text-align: center;
      line-height: 27px;
      font-size: 18px;
      font-weight: bold;
      color: $blue-2e;
      cursor: pointer;

      &:before {
        opacity: 0;
        transition: opacity 500ms;

        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;

        position: absolute;

        top: 0;
        left: 0;
        background-image: url("../../../img/landing/current-slide.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.active {
        color: white;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .landing-page {
    .carousel-wrap {
      padding: 0;
      width: initial;
    }

    .ant-carousel {
      margin-top: 93px;
      width: 1228px;

      .step {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 64px;
        padding-right: 64px;
      }

      .first-step {
        .info-block {
          width: 375px;
        }

        h1 {
          font-size: 52px;
          line-height: 1;
          margin-bottom: 42px;
        }

        h3 {
          font-size: 24px;
          line-height: 1;
          margin-bottom: 42px;
        }

        .info {
          line-height: initial;
          color: $gray-44;
        }

        .image-block {
          height: 391px;
          width: auto;
        }
      }
    }

    .divider {
      display: flex;
    }

    .slide-numbers {
      padding: 54px;

      .number-btn {
        height: 56px;
        width: 70px;

        margin-left: 0;
        margin-right: 0;

        line-height: 54px;
        font-size: 36px;
        font-weight: initial;
      }
    }
  }
}
