.landing-page {
  .create-page-block {
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;

    margin-left: 16px;
    margin-right: 16px;

    .try-it {
      color: $green-83;
    }

    .info-block {
      position: absolute;
      z-index: 15;
      padding-top: 40px;
    }

    h1 {
      color: white;
      font-size: 30px;
      line-height: 1.5;
      letter-spacing: 0.18px;
    }

    h3 {
      color: white;
      font-size: 18px;
      margin-bottom: 1em;
      letter-spacing: 0.11px;
    }

    .button-block {
      position: relative;
      display: none;
      align-items: center;

      margin-top: 113px;

      &.mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-top: 0;

        .tip-block {
          width: 100%;
          line-height: 1.5;
          letter-spacing: 0.08px;
          color: $white-fe;
          padding-left: 0;
        }

        .arrow-left {
          left: initial;
          right: 130px;
          bottom: 0;
          width: 76px;
        }
      }

      .tip-block {
        font-size: 14px;
        padding-left: 21px;
      }

      .arrow-left {
        position: absolute;
        left: 110px;
        bottom: -6px;
        width: 46px;
      }
    }

    .laptop-img {
      margin-top: 87px;
      opacity: 0.35;
      width: 100%;
    }

    .underline-left,
    .underline-right {
      position: absolute;
      height: 2px;
      bottom: -56px;
      background-color: $blue-2e;
    }

    .underline-left {
      left: -16px;
      width: 152px;
    }

    .underline-right {
      right: -16px;
      width: 16px;
    }
  }

  .key-features-block {
    position: relative;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 140px 16px 97px;

    h2 {
      font-size: 18px;
      line-height: 1.5;
      color: $blue-2e;
      margin: 8px;
    }

    .divider {
      width: 60px;
      height: 3px;
      margin-bottom: 32px;

      background-color: $blue-34;
      border-radius: 25px;
    }

    .features-wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 44px;
    }

    .feature-card {
      text-align: center;
      color: $blue-2e;

      padding-bottom: 40px;

      img {
        width: 168px;
        margin-bottom: 16px;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.1px;
        color: $blue-2e;

        br {
          display: none;
        }
      }

      .text {
        text-align: left;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .landing-page {
    .create-page-block {
      position: initial;
      flex-direction: row;

      margin-left: 0;
      margin-right: 0;

      h1 {
        font-size: 52px;
        line-height: 70px;
        letter-spacing: initial;
      }

      h3 {
        font-size: 24px;
        letter-spacing: initial;
      }

      .info-block {
        position: initial;
        padding-top: 85px;
        padding-left: 32px;
        width: 380px;
      }

      .button-block {
        display: flex;

        &.mobile {
          display: none;
        }
      }

      .underline-left,
      .underline-right {
        top: 489px;
        bottom: initial;
      }

      .underline-left {
        left: 0;;
        width: 345px;
      }

      .underline-right {
        right: 0;
        width: 70px;
      }

      .laptop-img {
        opacity: 1;
        width: 763px;
        margin-top: 0;
      }
    }

    .key-features-block {
      margin-top: 120px;
      margin-left: 0;
      margin-right: 0;

      h2 {
        font-size: 36px;
        line-height: initial;
        margin: 14px;
      }

      .divider {
        height: 4px;
        margin-bottom: 52px;

        border-radius: 2px;
      }

      .features-wrap {
        grid-template-columns: repeat(3, 1fr);
      }

      .feature-card {
        padding-bottom: 0;
        color: $gray-44;
        width: 236px;

        h3 {
          font-size: 24px;
          color: $blue-2e;

          font-weight: initial;
          line-height: initial;
          letter-spacing: initial;

          br {
            display: initial;
          }
        }

        img {
          width: 140px;
          margin-bottom: 35px;
        }

        .text {
          text-align: center;
        }
      }
    }
  }
}
