@font-face {
  font-family: 'Averta';
  src: url('../../fonts/averta/averta_black-webfont.woff2') format('woff2'),
  url('../../fonts/averta/averta_black-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'Averta';
  src: url('../../fonts/averta/averta_bold-webfont.woff2') format('woff2'),
  url('../../fonts/averta/averta_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../../fonts/averta/averta_light-webfont.woff2') format('woff2'),
  url('../../fonts/averta/averta_light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'Averta';
  src: url('../../fonts/averta/averta_semibold-webfont.woff2') format('woff2'),
  url('../../fonts/averta/averta_semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'Averta';
  src: url('../../fonts/averta/averta-webfont.woff2') format('woff2'),
  url('../../fonts/averta/averta-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
