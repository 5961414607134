.cookies-toast-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid rgba(0,0,0,.2);
  z-index: 999;

  .cookies-toast-wrapper {
    max-width: 1200px;
    padding: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}