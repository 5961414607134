.settings-container {
  flex: auto;
  background-color: white;

  height: 100%;
  width: 100%;

  .settings-content {
    padding: 16px;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: $gray-44;
  }

  .settings-menu-item {
    display: flex;
    margin-bottom: 16px;

    .icon-block {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: $blue-e1;
      overflow: hidden;

      margin-right: 16px;

      img {
        width: 24px;
      }
    }

    .info-block {
      flex: 1;

      h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: #2d3284;
        margin-bottom: 0;
      }

      .setting-info {
        font-size: 14px;
        line-height: 1.5;
        color: #444444;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;

    .settings-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      flex: auto;
      height: 100%;
    }

    .settings-block {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 82px;
      margin-bottom: 82px;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 23px;
    }

    .settings-menu-item {
      width: 250px;
      margin-right: 57px;
      cursor: pointer;
    }
  }
}
