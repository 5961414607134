.newsletter-editor {
  .letter-container-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: scroll;
  }

  .letter-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;

    .droppable-collumn {
      height: 100%;
    }

    .droppable-collumn-1 {
      // padding-right: 5px;
      border-right: 4px solid rgba(114, 114, 114, 0.15);
    }

    .content-grid {
      display: grid;
      grid-template-columns: 1fr;
      // grid-gap: 16px;

      &.one_column {
        grid-template-columns: 1fr;
      }

      &.two_columns {
        grid-template-columns: calc(50% + 4px) 50%;
        gap: 16px;

        @media screen and (max-width: $medium-screen) {
          grid-template-columns: calc(46% + 4px) 46%;
        }
      }

      &.two_columns_right {
        grid-template-columns: 2fr 3fr;
        gap: 16px;
      }

      &.two_columns_left {
        grid-template-columns: 3fr 2fr;
        gap: 16px;
      }
    }

    .letter-heading {
      display: none;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      // min-height: 43px;
      margin-bottom: 16px;
      padding: 8px 12px;

      background-color: $blue-34;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;

      font-size: 18px;
      font-weight: bold;
      color: $white-fe;

      &.active {
        display: flex;
      }

      .arrow-down {
        display: inline-block;
        height: 24px;
      }

      .newsletter-name {
        display: none;
      }
    }

    .letter-school-info {
      display: none;
      flex-direction: column;
      width: 100%;

      &.active {
        display: flex;
      }

      .logo-row {
        display: flex;
        align-items: center;
        padding: 12px 12px 12px 0;
        width: 100%;
        // flex: 0.4;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .logo {
        width: 100%;
        height: 100%;
      }

      .arrow-up {
        transform: rotate(180deg);
        width: 24px;
        margin-left: 34px;
      }

      .school-media-info {
        display: grid;
        grid-template-columns: 24px 1fr;
        grid-gap: 8px;
        align-items: center;

        height: 104px;
        // width: 100%;
        padding: 8px;
        margin-bottom: 16px;
        border-radius: 10px;
        background-color: #f4f4f9;

        font-size: 14px;

        img {
          height: 24px;
        }

        a {
          color: $gray-44;
        }
      }
    }

    .letter-footer {
      width: 100%;
      padding: 12px 24px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      background-color: #34c3ee;
      color: #fff;
      border-radius: 6px;
      word-break: break-all;
      margin-top: 12px;

      p {
        align-self: center;
        margin-bottom: 5px;
      }

      .school-media-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;

          a {
            color: #fff;
          }
        }

        img {
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }

  .controls-buttons-holder {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;

    padding-top: 16px;
    padding-bottom: 40px;

    .app-button {
      width: 100%
    }
  }

  .add-content {
    align-self: center;

    color: $green-83;
    border-radius: 25px;
    box-shadow: 1px 7px 20px 0 rgba(114, 114, 114, 0.15);
    border: solid 1px $gray-e1;
    background-color: $white-f4;

    margin-bottom: 16px;
    width: 168px;
  }
}

@media (min-width: $medium-screen) {
  .newsletter-editor {
    .display-content,
    .controls-buttons-holder {
      display: none;
    }

    .letter-container-wrap {
      &.hide-mobile {
        display: flex !important;
      }
    }

    .letter-container {
      width: 576px;
      // min-height: 100%;
      height: auto;
      flex: 1;

      margin: 14px 0;
      padding: 20px;
      background-color: white;

      .letter-school-info,
      .letter-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .arrow-down,
        .arrow-up {
          display: none;
        }
      }

      .letter-heading {
        .newsletter-name {
          display: initial;
          font-size: 16px;
          font-weight: normal;
        }
      }

      .letter-school-info {
        flex-direction: row;
        align-items: flex-end;

        .school-media-info,
        .logo-row {
          overflow: hidden;
          // flex: 1; 
          margin-bottom: 0;
          padding: 0;
        }

        .school-media-info {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 12px;
        }
      }
    }
  }
}
