.settings-subscription {
  .no-subs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .no-subs-header {
      font-size: 18px;
      font-weight: bold;
      color: $gray-44;
      text-align: center;
      margin-bottom: 4px;
    }

    .no-subs-content {
      font-size: 14px;
      text-align: center;
      color: $gray-44;
      margin-bottom: 87px;
    }
  }

  .payment-form {
    .cards-block {
      margin-bottom: 20px;
    }

    .button-holder {
      display: flex;
      justify-content: center;
    }
  }

  .subs-details-wrapper {
    display: flex;
    flex-direction: column;

    .subs-information {
      width: 100%;
      height: 190px;
      padding: 24px;
      margin-top: 30px;
      margin-bottom: 24px;

      border-radius: 25px;
      background-color: $white-f4;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin-bottom: 8px;
      }

      .subs-info-title {
        font-size: 18px;
        font-weight: bold;
        color: $blue-2d;
      }

      .subs-info-data {
        font-size: 16px;
        font-weight: 600;
        color: $gray-44;
      }
    }

    .link {
      font-weight: 300;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
    }

    .control-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .app-button {
        margin-bottom: 16px;
        width: 168px;
      }
    }
  }

  .save-button-wrap {
    display: flex;
    justify-content: center;
    margin-top: 54px;
  }
}

@media (min-width: $medium-screen) {
  .settings-subscription {
    h1 {
      font-size: 24px;
      text-align: center;
    }

    .no-subs {
      .no-subs-header {
        font-size: 24px;

        br {
          display: none;
        }
      }

      .no-subs-content {
        font-size: 16px;
      }

      .app-button {
        width: 263px;
        height: 57px;
        border-radius: 240px;
        font-size: 24px;
      }
    }

    .subs-details-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 1065px;

      h5,
      h1 {
        text-align: left;
      }

      .subs-information {
        height: 157px;
        width: 336px;

        p {
          margin-bottom: 8px;
        }

        .subs-info-title {
          font-size: 16px;
          font-weight: bold;
          color: $blue-2d;
        }

        .subs-info-data {
          font-size: 16px;
          font-weight: 600;
          color: $gray-44;
        }
      }

      .control-buttons {
        .app-button {
          margin-bottom: 24px;
          width: 263px;
          height: 57px;
          border-radius: 240px;
          font-size: 24px;
        }
      }
    }

    .save-button-wrap {
      .app-button {
        width: 263px;
        height: 57px;
        border-radius: 240px;
        font-size: 24px;
      }
    }
  }
}
