// Gray colors
$gray-e9: #e9e9f3;
$gray-44: #444444;
$gray-05: rgba(255, 255, 255, 0.5);
$gray-033: rgba(255, 255, 255, 0.33);
$gray-68-033: rgba(68, 68, 68, 0.33);
$gray-af: #afafaf;
$gray-a0: #a0a0ac;
$gray-66: #666772;
$gray-39: #393b62;
$gray-ea: #eaeaf3;
$gray-e1: #e1e1ee;

// White
$white-fe: #fefffe;
$white-f4: #f4f4f9;
$white-f1: #f1f1f7;

// Blue colors
$blue-2e: #2e3284;
$blue-34: #34c3ee;
$blue-24: #242c88;
$blue-2d: #2d3284;
$blue-e1: #e1e1ee;

// Green color
$green-83: #83d689;

// Orange color
$orange-ff: #ffc408;

// Red color
$red-ff: #ff4d4f;

// Globals
$background-color: $gray-e9;
$primary-color: $blue-34;
