.newsletter-editor {
  .content-to-choose {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 16px;

    width: 100%;

    .content-item-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content-item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 76px;
      height: 66px;
      padding: 9px;

      border-radius: 5px;
      border: solid 0.5px $gray-44;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .content-item-title {
      font-size: 11px;
      color: $gray-44;
      text-align: center;
      width: 100%;
    }
  }
}

@media (min-width: $medium-screen) {
  .newsletter-editor {
    .content-to-choose {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto 1fr;
      grid-gap: 9px;

      padding: 14px 24px;
      background-color: $white-f4;

      .content-item-wrap {
        background-color: white;
        height: fit-content;
        padding-bottom: 18px;
        border-radius: 5px;
        cursor: pointer;
      }

      .content-item {
        padding: 14px;
        border: none;
      }

      .content-item-title {
        font-weight: bold;
        color: $blue-2e;
      }
    }
  }
}
