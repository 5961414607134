.layout,
.landing-page {
  .sidebar-menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;

    height: 100vh;
    width: 244px;

    transform: translate3d(100%, 0, 0);
    transition: all 500ms ease 0s;

    background-image: linear-gradient(to top, #48c7d5, #5dcdb9);

    &.active {
      transform: initial;
    }

    .sidebar-body {
      .heading-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 25px 16px 40px;

        .logo {
          height: 17px;
        }
      }

      .ant-menu {
        background-color: transparent;

        .ant-menu-item {
          a {
            color: white;
          }
        }

        .ant-menu-item-selected {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .button-group {
        display: flex;
        flex-direction: column;

        width: 120px;
        margin: 30px 16px 0;

        .ant-btn {
          font-weight: bold;
          color: $blue-2e;
          border: none;

          &:last-child {
            margin-top: 16px;
          }

          &.try-it-btn {
            color: $green-83;

            .try-it {
              color: white
            }
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  transform: translate3d(100%, 0, 0);
  transition: opacity 300ms;
  opacity: 0;

  &.active {
    transform: initial;
    opacity: 1;
  }
}
