.newsletter-editor {
  .ant-layout {
    &.layout {
      height: 100vh;
    }
  }
  
  .ant-layout-content {
    background-color: white;
    padding: 16px;
  }

  &.public-page {
    .editor-header {
      justify-content: center;

      .letter-title {
        font-size: 16px;
        font-weight: 600;
        color: $white-fe;
      }
    }

    .ant-layout {
      .ant-layout-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 16px;
        background: white;
      }
    }

    .public-page-img {
      width: 169px;
      margin-bottom: 16px;
    }

    .page-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: $gray-44;
      margin-bottom: 8px;
    }

    .page-sub-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: $gray-44;
      margin-bottom: 8px;
    }

    .page-text-content {
      font-size: 11px;
      font-weight: 300;
      line-height: 1.5;
      text-align: center;
      color: $gray-44;

      width: 232px;
      margin-bottom: 16px;
    }

    .copy-block {
      position: relative;
      width: 100%;
      margin-bottom: 54px;

      img {
        position: absolute;
        top: 10px;
        right: 16px;
        z-index: 100;
        height: 24px;
        cursor: pointer;
      }

      input {
        display: flex;
        align-items: center;

        width: 100%;
        height: 43px;
        padding: 10px 56px 10px 10px;

        border-radius: 500px;
        border: none;
        background: $gray-e1;

        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: $blue-2d;

        &:focus {
          outline: none;
        }
      }
    }

    .buttons-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .app-button {
        flex: 1;
        font-size: 11px;

        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


@media (min-width: $medium-screen) {
  .newsletter-editor {
    .ant-layout {
      .ant-layout-content {
        display: grid;
        grid-template-columns: 6fr 4fr;
        background-color: $gray-e9;
        min-height: calc(100vh - 80px - 76px);
        padding: 0;
      }
    }

    &.public-page {
      .ant-layout {
        .ant-layout-content {
          padding-top: 140px;
        }
      }

      .copy-block {
        width: 580px;
        margin-bottom: 32px;
      }

      .page-sub-title {
        font-size: 16px;
      }

      .page-text-content {
        font-weight: bold;
        width: initial;
      }

      .buttons-block {
        justify-content: center;

        .app-button {
          flex: initial;
          font-size: 11px;

          margin-right: 14px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
