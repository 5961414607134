.invite-user {

  .button-block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
  }

  .form-uploader {
    .app-button {
      background-color: $orange-ff;
    }
  }

  .custom-dropdown .ant-select-selector {
    border-radius: 5px;
    background-color: #f4f4f9 !important;
    border: solid 1px #e1e1ee !important;
    // padding: 12px 16px !important;
    outline: none !important;   
  }
}

@media (min-width: $medium-screen) {
  .invite-user {
    display: flex;
    flex-direction: column;

    .page-content-body {
      h1 {
        font-size: 24px;
      }
    }

    .page-content-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100%;
      flex: 1;

      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        width: 540px;

        .full-size {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}
