.ant-layout-header {
  &.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 67px;
    padding-left: 28px;
    padding-right: 28px;

    box-shadow: 0 0 15px 0 rgba(12, 12, 12, 0.25);
    background-color: $blue-2e;
  }

  .logo-img {
    height: 20px;
  }

  .ant-menu {
    line-height: 33px;

    background-color: transparent;
    box-shadow: none;
    border-bottom: none;

    a {
      font-size: 16px;
      color: white;
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      color: white;
      border-color: $blue-34;
    }

    .ant-menu-item {
      a {
        border-bottom-width: 2px;

        &:hover {
          color: $blue-34;
          border-color: $blue-34;
        }
      }

      &:hover {
        color: $blue-34;
        border-color: $blue-34;
      }
    }
  }
}

.header-content {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: #fff;
    font-weight: 500;

    a {
      color: inherit;
    }

    a.active {
      color: $blue-34;
    }
  }
}

.header-additional-block {
  display: flex;

  .ant-btn-primary {
    background-color: $blue-34;
    border-color: $blue-34;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    padding: 6px 12px;
  }

  .app-button {
    margin-left: 10px;
  }
}

.mobile-tabs {
  background-color: white;

  .ant-menu {
    display: flex;
    justify-content: space-between;
    line-height: 33px;

    background-color: transparent;
    box-shadow: none;
    border-bottom: none;

    a {
      font-size: 16px;
      color: $blue-2d;
      font-weight: 600;
      line-height: 1.5;
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      color: $blue-2d;
      border-color: $blue-34;

      a {
        font-weight: bold;
      }
    }

    .ant-menu-item {
      padding-top: 16px;
      margin: 0;

      a {
        border-bottom-width: 2px;

        &:hover {
          color: $blue-2d;
          border-color: $blue-34;
        }
      }

      &:hover {
        color: $blue-2d;
        border-color: $blue-34;
      }
    }
  }
}

@media (max-width: $small-screen) {
  .header-content {
    display: none;
  }
}

@media (min-width: $medium-screen) {
  .mobile-tabs {
    display: none;
  }
}
