.start-screen {
  display: flex;
  flex-direction: column;

  .start-screen-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .get-started {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .page-img {
        height: 169px;
      }

      .start-screen-title {
        font-size: 18px;
        font-weight: bold;
        color: $gray-44;

        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }

  .start-stepper {
    display: flex;
    align-items: center;

    .step-item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30px;
      height: 30px;

      border-radius: 50%;
      border: solid 2px $blue-2d;
      background-color: $white-fe;
      cursor: pointer;

      font-size: 15px;
      font-weight: 700;
      color: $blue-2d;

      &.active {
        background-color: $blue-2d;
        color: white
      }
    }

    .step-divider {
      flex: 1;
      height: 3px;
      width: 46px;
      background-color: $blue-2d;
    }
  }

  .start-colors-container {
    display: flex;
    min-width: 25%;
    align-items: center;
    justify-content: center;

    .form-input {
      flex: 1;
      margin: 0 8px 16px;
    }
  }

  .question {
    display: flex;
    align-items: flex-start;

    font-size: 16px;
    font-weight: 600;
    color: $gray-44;

    margin-top: 16px;
    width: 100%;

    .step-item {
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form-uploader {
      margin: 32px 0;
    }
  }

  .school-input {
    margin-top: 40px;
    margin-bottom: 76px;

    input {
      height: 51px;
      font-size: 30px;
      font-weight: bold;
      color: $gray-44;
      text-align: center;

      border: none;
      border-bottom: 3px solid $blue-34;
      background-color: transparent;
      border-radius: 0;
    }
  }

  .choose-template {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 32px;

    margin-top: 27px;
    margin-bottom: 32px;
    width: 100%;

    .template-item {
      position: relative;
      text-align: center;
      cursor: pointer;

      &.inactive {
        opacity: 0.25;
        pointer-events: none;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        color: $blue-2d;
        margin-top: 8px;
        margin-bottom: 0;
      }

      .template-img {
        width: 100%;
      }

      .mark-icon {
        position: absolute;
        top: -16px;
        right: -16px;
        z-index: 10;

        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .start-screen {
    .start-screen-body {
      .get-started {
        justify-content: center;

        .page-img {
          height: 208px;
        }

        .start-screen-title {
          font-size: 24px;
          margin-top: 20px;
        }
      }
    }

    .start-stepper {
      margin: 52px 0;

      .step-divider {
        width: 17px;
      }
    }

    .question {
      width: initial;
      margin-top: 0;
      font-size: 24px;

      .step-item {
        display: none;
      }
    }

    .school-input {
      width: 568px;

      input {
        font-size: 32px;
        height: 67px;
      }
    }

    .app-button {
      height: 57px;
      font-size: 24px;
      padding-left: 53px;
      padding-right: 53px;
      border-radius: 240px;
    }

    form {
      .form-uploader {
        width: 1177px;
        height: 336px;

        .upload-icon {
          height: 48px;
          width: 48px;
        }

        .upload-text {
          font-size: 24px;
        }

        .app-button {
          font-size: 26px;
          font-weight: normal;
        }
      }
    }

    .choose-template {
      grid-template-columns: repeat(4, 153px);
      grid-column-gap: 57px;
      width: initial;
      margin-bottom: 68px;

      .template-item {

        p {
          font-size: 24px;
        }

        .sub-text {
          font-weight: 300;
        }
      }
    }
  }
}
