.newsletters {

  .search-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .search-input-wrap {
    position: relative;
    margin-left: 10px;

    input {
      width: 100%;
      height: 40px;
      padding-left: 52px;
      border-radius: 25px;

      background-color: $gray-ea;
      border: none;

      font-size: 14px;
      color: $blue-2d;
      outline: none;

      &::placeholder {
        color: $blue-2d;
      }
    }

    .search-icon {
      position: absolute;
      top: 8px;
      left: 20px;
      z-index: 10;

      height: 24px;
    }
  }

  .controls-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;

    height: 100vh;
    width: 100vw;

    opacity: 0.5;
    background-color: $gray-44;
  }

  .filters-block {
    position: relative;
    display: flex;

    width: 100%;
    height: 48px;
    margin-top: 24px;

    border-radius: 5px;
    border: solid 0.5px $gray-a0;

    .filters-item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50%;
      height: 100%;
      padding: 0 18px;

      border-right: 2px solid $gray-66;
      cursor: pointer;

      &:last-child {
        border: none;
      }
    }

    span {
      flex: 1;
      font-size: 14px;
      color: $blue-2d;
      white-space: nowrap;

      &.value {
        display: none;
      }
    }

    .filter-icon {
      height: 24px;
      margin-right: 8px;
    }

    .filter-menu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      width: 100%;

      border-radius: 5px;
      border: solid 0.5px $gray-a0;
      background-color: $white-fe;

      &.active {
        display: block;
      }

      .filter-menu-head {
        display: flex;
        align-items: center;

        width: 100%;
        height: 48px;
        padding: 0 18px;

        span {
          font-weight: bold;
          font-size: 18px;
        }

        .arrow-up {
          transform: rotate(180deg);
          cursor: pointer;
        }
      }

      .filter-menu-body {
        padding: 0 18px;


        .filter-menu-item {
          font-size: 16px;
          font-weight: 600;
          color: $gray-44;
          margin: 8px 0;
          height: 24px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover,
          &.active {
            font-size: 18px;
            font-weight: bold;
            height: 28px;
          }
        }
      }
    }
  }

  .newsletters-item {
    display: flex;
    align-items: center;

    padding: 16px 0;
    height: 116px;
    border-bottom: 2px solid $gray-e9;

    .letter-status {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 24px;

      border-radius: 8px;
      background-color: #34c3ef;

      font-size: 11px;
      font-weight: 300;
      color: $white-fe;

      &.published {
        background-color: $green-83;
      }
    }

    .image-block {
      flex-basis: 60px;

      img {
        width: 60px;
      }
    }

    .info-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      flex: 1;
      padding-left: 8px;
      height: 100%;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: $blue-2d;
        line-height: 1.5;
      }

      .last-seen {
        font-size: 16px;
        font-weight: 600;
        color: black;
        line-height: 1.5;

        .date {
          font-size: 14px;
          color: $gray-44;
          margin-left: 10px;
        }
      }
    }
  }

  .newsletter-options {
    position: relative;

    .arrow-icon {
      display: inline-block;
      width: 14px;
      margin-left: 10px;
    }
  }
}

@media (min-width: $medium-screen) {
  .newsletters {
    .page-content-body {
      padding-right: 205px;
      padding-left: 205px;
    }

    .header-block {
      display: flex;
      align-items: center;
      padding-bottom: 15px;

      border-bottom: 2px solid $gray-e9;

      h1 {
        font-size: 24px;
        margin-bottom: 0;
        margin-right: 24px;
      }

      .search-input-wrap {
        position: relative;

        input {
          width: 288px;
          height: 28px;
          padding-left: 35px;

          font-size: 12px;
        }

        .search-icon {
          left: 10px;
          height: 14px;
        }
      }

      .filters-block {
        width: initial;
        height: 28px;
        margin-top: 0;

        border: none;

        .filters-item {
          width: initial;
          padding: 0 9px;
          margin-left: 10px;

          border: solid 1px $gray-e9;
          border-radius: 5px;

          .arrow-up {
            margin-left: 7px;
          }
        }

        .filter-icon {
          height: 14px;
        }

        span {
          font-size: 12px;

          &.value {
            display: block;
            font-weight: normal;
            color: $gray-44;
            margin-right: 7px;
          }
        }
      }
    }

    .newsletters-item {
      &:hover {
        .newsletter-options {
          opacity: 1;
        }
      }

      .letter-status {
        width: initial;
        height: 13px;
        padding: 0 6px;
        margin-left: 9px;

        border-radius: 4px;

        font-size: 7px;
        text-transform: uppercase;

        &.mobile {
          display: none;
        }
      }

      .info-block {
        justify-content: center;

        .title {
          display: flex;
          align-items: center;
        }

        .last-seen {
          font-size: 12px;

          .date {
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }

    .newsletter-options {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}
