.landing-page {
  .landing-page-header {
    position: relative;

    .header-block {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 32px 16px;

      z-index: 5;

      .logo {
        height: 27px;
      }

      .burger-menu {
        display: flex;
        align-items: center;

        svg {
          height: 17px;
          width: 100%;
        }
      }

      .ant-menu {
        background-color: transparent;
        border-bottom: none;

        a {
          font-size: 16px;
          font-weight: bold;
          color: $blue-2e;
        }
      }

      .ant-menu-item {
        position: relative;
        border-bottom: none;

        &:before {
          opacity: 0;
          transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

          position: absolute;
          content: '';
          bottom: 0;
          background-color: white;
          border-radius: 2px;

          height: 4px;
          width: 100%;
        }
      }

      .ant-menu-item-selected {
        a {
          color: $blue-2e;
        }

        // &:before {
        //   opacity: 1;
        // }
      }

      .button-group {
        display: none;

        .ant-btn {
          font-weight: bold;
          color: $blue-2e;
          border: none;

          &:last-child {
            margin-left: 10px;
          }

          &.try-it-btn {
            color: white;
          }

          &.try-it-btn:hover {
            color: #40a9ff;
          }

          &.try-it-btn:focus {
            color: #40a9ff;
          }
        }
      }
    }

    .header-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;

      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .landing-page {
    .landing-page-header {

      .header-block {
        padding: 53px 60px;

        .logo {
          height: 37px;
        }

        .button-group {
          display: flex;
        }

        .burger-menu {
          display: none;
        }
      }

      .header-background {
        &.mobile {
          display: none;
        }

        &.desktop {
          display: block;
        }
      }
    }
  }
}
