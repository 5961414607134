.landing-page {
  position: relative;

  .landing-page-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;

    .change-password-submit {
      font-weight: bold;
      color: #2e3284;
      // border: none;

      &:hover {
        border-color: #2e3284;
      }
    }

    .terms-container {
      width: 95%;
      margin: 50px auto;

      h1 {
        color: $blue-2e;
      }
    }

    .privacy-container {
      width: 95%;
      margin: 50px auto;

      h1 {
        color: $blue-2e;
      }

      table {
        border:1px solid black;
        margin-bottom: 1em;

        th {
          padding: 8px;
        }

        td {
          padding: 8px;
          border: 1px solid black;
        }
      }
    }
  }
}

.try-it-btn {
  background-color: $blue-2e;
  color: white;
  font-weight: bold;

  border-bottom-left-radius: 0;
  border: none;
  width: 120px;

  .try-it {
    margin-right: 5px;
    color: $blue-34
  }
}
