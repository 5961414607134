.choose-plan {
  .page-content-body {
    h1 {
      margin-bottom: 40px;
    }
  }

  .billing-info {
    cursor: pointer;
  }
}
