.newsletter-editor {
  .editor-header {
    display: flex;
    align-items: center;

    height: 80px;
    width: 100%;
    padding: 0 16px;
    background-color: $gray-44;

    .app-logo {
      width: 21px;
      margin-right: 8px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .title-field {
      // width: 152px;
      width: 50%;

      input {
        background-color: transparent;
        outline: none;

        height: 20px;
        padding: 0;

        border: none;
        border-radius: 0;
        border-bottom: 1px solid $white-fe;

        font-size: 16px;
        font-weight: 600;
        color: $white-fe;
      }
    }

    .edit-icon {
      height: 14px;
      margin-left: 4px;
    }
  }

  .editor-sub-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 37px;
    padding: 0 16px;

    background-color: $gray-ea;

    font-size: 14px;
    color: $gray-44;

    .delete {
      color: $red-ff;
    }

    .arrow-left {
      display: inline-block;
      width: 24px;
      transform: rotate(90deg);
      margin-right: 8px;
    }
  }
}

@media (min-width: $medium-screen) {
  .newsletter-editor {
    .editor-sub-head {
      display: none;
    }
  }
}
