
.modal-content {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  max-width: 450px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  a {
    height: 40px;
    font-size: 1.5em;
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  transform: translate3d(100%, 0, 0);
  transition: opacity 300ms;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  &.active {
    transform: initial;
    opacity: 1;
  }
}
