#root {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.ant-layout {
  &.layout {
    min-height: 100vh;

    background-color: $background-color;

    .ant-layout-content {
      display: flex;
      flex-direction: column;
    }
  }
}

.page-content {
  flex: auto;
  background-color: white;

  height: 100%;
  width: 100%;

  .page-content-body {
    height: 100%;
    padding: 16px;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: $gray-44;
      margin-bottom: 16px;
    }

    h5 {
      font-size: 12px;
      font-weight: normal;
      color: $gray-44;
    }
  }

  .color-picker-container {
    .form-input input {
      padding: 0;
    }

    .form-uploader {
      margin-bottom: 24px;
    }
  }
}

@media (min-width: $medium-screen) {
  .ant-layout {
    &.layout {
      .ant-layout-content {
        padding: 14px;
      }
    }
  }

  .page-content {
    border-radius: 6px;

    .page-content-body {
      h5 {
        text-align: center;
      }
    }
  }
}
