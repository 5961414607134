.letter-content-text h1 {
  font-size: 33px !important;
}

.public-DraftEditor-content h1 {
  font-size: 33px;
}

.newsletter-editor {
  .field-container-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .event-item {
      width: 100%;
      padding: 16px 0;
      border-bottom: 1px solid $gray-e1;

      &.collapsed {
        .arrow-collapse {
          transform: rotate(180deg);
        }

        .event-controls {
          display: flex;
        }
      }

      .event-content {
        display: grid;
        grid-template-columns: 63px 1fr 24px;
        grid-column-gap: 16px;
        align-items: center;
      }

      .event-date {
        font-size: 14px;
        color: $gray-44;
      }

      .event-name {
        font-size: 16px;
        font-weight: 600;
        color: $blue-2d;
      }

      .arrow-collapse {
        width: 24px;
        height: 24px;
      }

      .event-controls {
        display: none;
        justify-content: space-around;
        align-items: center;
      }
    }

    .docs-uploader {
      width: 100%;

      .ant-upload {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
      }

      .docs-uploader-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        color: $blue-2d;

        margin-bottom: 8px;
        width: 100%;

        .delete-button {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $red-ff;

          svg {
            width: 20px;
          }
        }
      }

      input {
        width: 100%;
        height: 43px;
        border-radius: 5px;
        border: solid 1px $gray-e1;
        padding: 12px 16px;
        outline: none;

        margin-bottom: 16px;
      }
    }

    .form-uploader {
      display: flex;
      background-color: transparent;

      margin-bottom: 16px;
    }

    .form-input {
      input {
        border: solid 1px $gray-e1;
        background-color: $white-fe;
      }
    }

    .field-container-uploader {
      .app-button {
        background-color: $orange-ff;
      }
    }

    .text-editor {
      min-height: 326px;
      margin-bottom: 16px;
      font-family: inherit;
    }

    .app-button {
      margin-top: 16px;
      width: fit-content;
    }

    .field-buttons {
      .app-button {
        width: 168px;
        margin-bottom: 16px;
      }
    }

    .field-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 14px;
      color: $blue-2d;
    }

    .field-row {
      display: flex;
      align-items: center;

      margin: 16px 0;

      .delete-button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $red-ff;

        svg {
          width: 20px;
        }
      }

      .form-input {
        position: relative;
        flex: 1;
        margin-right: 16px;
        margin-bottom: 0;

        .label-block {
          display: none;
        }

        &.percents {
          flex: initial;
          width: 80px;

          &:after {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 10;

            display: flex;
            align-items: center;
            justify-content: center;

            height: 43px;
            width: 30px;

            border-radius: 5px;
            border: solid 1px #e1e1ee;
            background-color: #fefefe;

            content: '%';
          }

          input {
            background-color: $white-f4;
          }
        }

        input {
          border-color: $gray-e1;
        }
      }
    }

    .add-row-btn {
      margin-bottom: 16px;
    }
  }

  .edit-event-field {
    .date-time-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
    }

    .form-input {
      input {
        background-color: white;
      }
    }
  }

  .letter-spacing {
    margin: 8px 0;
  }

  .letter-content-heading {
    font-size: 18px;
    font-weight: bold;
    color: $gray-44;
  }

  .letter-content-text {
    padding: 8px;
    border-radius: 5px;
    word-break: break-word;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }

    p {
      margin-bottom: 0;
    }
  }

  .letter-content-bordered-text {
    margin: 0 8px;
  }

  .letter-content-divider {
    width: 100%;
    border: 3px solid $gray-44;
  }

  .letter-content-socials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    img {
      margin: 0 16px 16px 0;
      height: 45px;
    }
  }

  .letter-content-img {
    width: 100%;
    margin: 6px 0;
  }

  .letter-content-img-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    margin: 6px 8px;

    .item {
      width: 100%;
      height: 100px;
      padding-top: 65%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .letter-content-button {
    width: fit-content;
    height: fit-content;
    word-break: break-word;
    white-space: break-spaces;
    padding: 8px 16px;
  }

  .letter-content-star {
    position: relative;
    width: 100%;
    height: fit-content;
    // background-image: url('../../../../assets/img/editor/start-of-the-week.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;

    .frame {
      width: 100%;
    }

    .frame-body {
      // position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      // padding: 24px;
      // padding-top: 20%;

      // padding-top: 10%;
      width: 100%;
      height: 100%;

      text-align: center;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        width: 75%;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
        color: $gray-44;
        overflow: hidden;
        // text-overflow: ellipsis;
        word-break: break-word;

        &.name {
          font-size: 20px;
        }
      }
    }
  }

  .letter-content-attendance {
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    hyphens: auto;
    overflow: hidden;
    h1 {
      font-size: 18px;
      font-weight: bold;
      color: $gray-44;
    }

    p {
      margin: 4px;
      word-break: break-word;
    }

    .progress-bar-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        padding-left: 10px;
      }
    }

    .progress-bar {
      flex: 1;
      height: 43px;

      border-radius: 5px;
      border: solid 1px $gray-e1;
      background-color: $white-f4;
      overflow: hidden;

      .percents-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 100%;
        padding: 0 16px;

        background-color: $orange-ff;
        overflow: hidden;
        white-space: nowrap;

        .name {
          font-size: 16px;
          font-weight: 600;
          color: $gray-44;
        }
      }
    }
  }

  .letter-content-events {
    .event-item {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 60px 1fr;

      padding: 8px 0;
      border-bottom: 1px solid $gray-e1;

      .event-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 60px;
        height: 60px;

        border-radius: 5px;
        background-color: $red-ff;

        font-size: 18px;
        font-weight: bold;
        color: $white-f4;

        .mount {
          font-size: 14px;
          font-weight: normal;
        }
      }

      .event-time {
        font-size: 11px;
        font-weight: 300;
        color: $red-ff;
        text-align: center;
        width: 100%;
      }

      .event-content {
        // word-break: break-all;
        hyphens: auto;

        .title {
          font-size: 16px;
          font-weight: 600;
          color: $gray-44;
        }

        .description {
          font-size: 14px;
          color: $gray-44;
          word-wrap: break-word;
          hyphens: none;
          white-space: pre-line;
        }
      }
    }
  }

  .letter-content-footer {
    // background-color: $blue-34;
    color: white;
    width: 100%;
    padding: 8px;
    border-radius: 10px;
  }

  .field-desktop-controls {
    display: none;
  }
}

.normal-editor-divider {
  width: 100%;
  border-bottom: 4px solid $gray-44;
  margin-top: 10px;
}

.dash-editor-divider {
  width: 100%;
  border-bottom: 4px dashed $gray-44;
  margin-top: 10px;
}


@media (min-width: $medium-screen) {
  .newsletter-editor {
    .field-container-wrap {
      background-color: $white-f4;
      padding: 24px;
    }

    .content-grid {
      &.two_columns_left,
      &.two_columns {
        height: 100%;
        .letter-content-star {
          .frame-body {
            p {
              font-size: 18px;

              &.name {
                font-size: 22px;
              }
            }
          }
        }
      }

      &.two_columns_right {
        .letter-content-star {
          .frame-body {
            p {
              font-size: 14px;

              &.name {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .field-container {
      .field-buttons {
        display: none;
      }

      .event-item {
        display: flex;

        .arrow-collapse {
          display: none;
        }

        .event-content {
          flex: 1;
        }

        .event-controls {
          display: flex;

          .app-button {
            margin-top: 0;
            margin-left: 4px;
          }
        }
      }

      .color-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
      }
    }

    .letter-content-star {
      .frame-body {
        p {
          font-size: 24px;

          &.name {
            font-size: 32px;
          }
        }
      }
    }

    .field-desktop-controls-container {
      margin-top: 65px;
      margin-bottom: 26px;
    }

    .field-desktop-headline {
      color: #2e3284;
      font-weight: 600;
    }

    .field-desktop-controls {
      display: flex;
      align-items: center;

      .arrow-left {
        display: inline-block;
        width: 24px;
        transform: rotate(90deg);
        margin-right: 8px;
      }

      .cancel-button {
        cursor: pointer;
      }

      .buttons-holder {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        .blue {
          margin-right: 4px;
        }
      }
    }
  }
}
